<template>
  <div class="user-pages">
    <div class="menu-wrap">
      <div
        class="menu-action"
        :class="{ active: isCurrent(menu) }"
        v-for="menu in menuList"
        :key="menu.route"
        @click="onMenuClick(menu)"
      >
        {{ menu.name }}
      </div>
    </div>
    <div class="container">
      <router-view v-slot="{ Component, route }">
        <keep-alive v-if="route.meta?.keepAlive">
          <component :is="Component"></component>
        </keep-alive>
        <component :is="Component" v-else></component>
      </router-view>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
export default {
  name: 'user',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const menuList = [
      { name: '资料设置', route: '/user/info' },
      { name: '修改密码', route: '/user/password' },
      { name: '登录记录', route: '/user/logs' },
      { name: '系统通知', route: '/user/notify' },
      { name: '消息设置', route: '/user/message-settings' }
    ]
    const isCurrent = menu => {
      return route.path.startsWith(menu.route)
    }
    const onMenuClick = menu => {
      router.push({ path: menu.route })
    }
    return {
      menuList,
      onMenuClick,
      isCurrent
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
